import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Faq } from "types/faqs"
import { Container } from "components/library"
import { Check } from "react-feather"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import { BLOG_BASE_URL } from "variables"
import { Faqs } from "components/common/faqs"
import { ComparisonTable, ComparisonTableProps } from "./pricing-comparison-table"
import { UserCountInput } from "./pricing-user-count-input"
import { PricingPlans } from "./pricing-plans"

type Query = {
	allFaq: {
		nodes: Faq[]
	}
}

const applications: ComparisonTableProps["items"] = [
	{
		title: "pricing.comparison-table.application.features.1",
		enterprise: <Check />,
		professional: <Check />,
	},
	{
		title: "pricing.comparison-table.application.features.2",
		enterprise: <Check />,
		professional: <Check />,
	},
	{
		title: "pricing.comparison-table.application.features.3",
		enterprise: <Check />,
		professional: <Check />,
	},
	{
		title: "pricing.comparison-table.application.features.4",
		enterprise: <Check />,
		professional: <Check />,
	},
	{
		title: "pricing.comparison-table.application.features.5",
		enterprise: <Check />,
		professional: <Check />,
	},
	{
		title: "pricing.comparison-table.application.features.6",
		enterprise: <Check />,
		professional: <Check />,
	},
]

const developerPlatform: ComparisonTableProps["items"] = [
	{
		title: "pricing.comparison-table.developer.features.1",
		enterprise: <Check />,
		professional: <Check />,
	},
]

const adminProjectControl: ComparisonTableProps["items"] = [
	{
		title: "pricing.comparison-table.admin.features.1",
		enterprise: <Check />,
		professional: <Check />,
	},
	{
		title: "pricing.comparison-table.admin.features.2",
		enterprise: <Check />,
		professional: <Check />,
	},
	{
		title: "pricing.comparison-table.admin.features.3",
		enterprise: <Check />,
		professional: <Check />,
	},
]

const professionalServices: ComparisonTableProps["items"] = [
	{
		title: "pricing.comparison-table.services-n-support.features.1",
		enterprise: <Check />,
		professional: (
			<span>
				<Trans i18nKey="common.words.basic" />
			</span>
		),
	},
	{
		title: "pricing.comparison-table.services-n-support.features.2",
		enterprise: <Check />,
		professional: (
			<span>
				<Trans i18nKey="common.words.limited" />
			</span>
		),
	},
	{
		title: "pricing.comparison-table.services-n-support.features.3",
		enterprise: <Check />,
		professional: <Check />,
	},
	{
		title: "pricing.comparison-table.services-n-support.features.4",
		enterprise: <Check />,
		professional: <Check />,
	},
]

export const Wrapper = () => {
	const {
		allFaq: { nodes: faqs },
	} = useStaticQuery<Query>(graphql`
		{
			allFaq {
				nodes {
					id
					question
					answer
					categories {
						id
						name
					}
				}
			}
		}
	`)

	const { t } = useTranslation()
	const [userCount, setUserCount] = useState(5)
	const pricingFaqs = faqs.filter((faq) => {
		const categories = faq.categories.map((cat) => cat.name)
		return (
			categories.includes("Billing") && categories.includes("Polymerize Labs")
		)
	})

	return (
		<Container className="pt-10 sm:pt-20 pb-20 sm:pb-32 flex flex-col gap-20">
			<section className="flex flex-col gap-16">
				<span className="text-3xl text-center tracking-wider sm:text-7xl">
					{t("pricing.heading")}
				</span>

				<UserCountInput userCount={userCount} setUserCount={setUserCount} />
				<PricingPlans userCount={userCount} />

				<ComparisonTable
					heading="pricing.comparison-table.application.heading"
					items={applications}
				/>
				<ComparisonTable
					heading="pricing.comparison-table.developer.heading"
					items={developerPlatform}
				/>
				<ComparisonTable
					heading="pricing.comparison-table.admin.heading"
					items={adminProjectControl}
				/>

				<ComparisonTable
					heading="pricing.comparison-table.services-n-support.heading"
					items={professionalServices}
				/>
			</section>

			<Faqs faqs={pricingFaqs} />

			<section className="flex flex-col gap-6 items-center">
				<h3 className="text-4xl sm:text-5xl">
					{t("pricing.contact-us.heading")}
				</h3>
				<p className="text-lg sm:text-xl">
					<Trans
						i18nKey="pricing.contact-us.desc"
						components={{
							gatsbyanchor: <Link to="/contact-us/" />,
							anchor: <a />,
						}}
						values={{
							blog: BLOG_BASE_URL,
						}}
					/>
				</p>
			</section>
		</Container>
	)
}
